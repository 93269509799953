import { Link } from "react-router-dom"

import styles from "./Start.module.css"

const Start = () => {
  return (
    <div className={styles.start}>
      <div className={styles.startTextWrapper}>
        <div className={styles.startText}>
          Hello, I'm <span>Cole Rutledge</span>. <br />
          I'm a full-stack web developer.
        </div>
        <div className={styles.startButton}>
          <Link to="/about" className={styles.startButtonLink}>
            View my work {"->"}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Start
