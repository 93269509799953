import {
  projectDetails,
  projectTitle,
  buttonContainer,
  buttonLink,
} from "./ProjectDetails.module.css"

const ProjectDetails = ({ name, description, tech, demoLink, ghLink }) => {
  return (
    <div className={projectDetails}>
      <div className={projectTitle}>
        <h1>{name}</h1>
        <p>
          <i>{description}</i>
        </p>
        <p>
          <i>{tech}</i>
        </p>
      </div>
      <div className={buttonContainer}>
        <a
          href={demoLink}
          target="_blank"
          className={buttonLink}
          rel="noreferrer"
        >
          Demo
        </a>
        <a
          href={ghLink}
          target="_blank"
          className={buttonLink}
          rel="noreferrer"
        >
          Code
        </a>
      </div>
    </div>
  )
}

export default ProjectDetails
