import { Link } from "react-router-dom"

import styles from "./TopNav.module.css"
import { linkedinPNG, githubPNG, twitterPNG, resumePNG } from "../static/assets"

const NavIcon = ({ staticLink, linkUrl, altTag }) => {
  return (
    <a href={linkUrl} target="_blank" rel="noreferrer">
      <img src={staticLink} height="32px" width="32px" alt={altTag} />
    </a>
  )
}

const TopNav = () => {
  return (
    <header>
      <nav>
        <div className={styles.navItem}>
          <Link to="/" className={styles.navLink}>
            HOME
          </Link>
        </div>
        <div className={styles.navIcons}>
          <div className="iconContainer">
            <NavIcon
              staticLink={linkedinPNG}
              linkUrl="https://www.linkedin.com/in/colerutledge/"
              altTag="linkedin-ico"
            />
            <NavIcon
              staticLink={githubPNG}
              linkUrl="https://github.com/ColeRutledge"
              altTag="github-ico"
            />
            <NavIcon
              staticLink={twitterPNG}
              linkUrl="https://twitter.com/ColeRutledge"
              altTag="twitter-ico"
            />
            <NavIcon
              staticLink={resumePNG}
              linkUrl="https://docs.google.com/document/d/13y5MLByh7rpOLKND2x5jd00PPmzsigXqVovsXHHjs7Q/export?format=pdf"
              altTag="resume-ico"
            />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default TopNav
