import AboutInfo from "./AboutInfo"
import NavFooter from "./NavFooter"
import Project from "./Project"
import TopNav from "./TopNav"

import {
  taskapi,
  spoofify,
  algoReact,
  pureSearch,
  asanaClone,
} from "../static/assets"

const About = () => {
  return (
    <>
      <TopNav />
      <AboutInfo />
      <Project
        name="Taskapi"
        description="an API server for a task management application"
        tech="Python, FastAPI, Docker, SQLAlchemy, OpenAPI, pytest"
        demoLink="https://asana.colerutledge.dev/docs"
        ghLink="https://github.com/ColeRutledge/asana_fastapi"
        image={taskapi}
        imageAlt="taskapi.png"
        reversed={false}
      />
      <Project
        name="Spoofify"
        description="a clone of the popular music streaming service"
        tech="Python, Flask, ReactJS, PostgreSQL"
        demoLink="https://spotify-clone-aa-react.herokuapp.com/"
        ghLink="https://github.com/ColeRutledge/spotify-clone"
        image={spoofify}
        imageAlt="spoofify.png"
        reversed={true}
      />
      <Project
        name="AlgoReact"
        description="a sorting algorithm visualizer"
        tech="JavaScript, ReactJS, CSS, HTML"
        demoLink="https://algo.colerutledge.dev/"
        ghLink="https://github.com/ColeRutledge/algo-react"
        image={algoReact}
        imageAlt="algoReact.png"
        reversed={false}
      />
      <Project
        name="PureSearch"
        description="a data scraper and job board aggregator"
        tech="Python, Pandas, Flask, ReactJS, PostgreSQL"
        demoLink="https://pure-search-client.herokuapp.com/"
        ghLink="https://github.com/ColeRutledge/pure-search"
        image={pureSearch}
        imageAlt="pureSearch.png"
        reversed={true}
      />
      <Project
        name="CARL"
        description="a clone of the team-based workflow management application, Asana"
        tech="Node.js, Express, ReactJS, PostgreSQL"
        demoLink="https://asana-clone-aa.herokuapp.com/"
        ghLink="https://github.com/ColeRutledge/Asana-Clone"
        image={asanaClone}
        imageAlt="asanaClone.png"
        reversed={false}
      />
      <NavFooter />
    </>
  )
}

export default About
