import { useState } from "react"

import ProjectDetails from "./ProjectDetails"
import {
  section,
  sectionShaded,
  projectContainer,
  projectContainerReverse,
  projectImage,
  projectImageReverse,
  projectImageZoomed,
  projectImageReversedZoomed,
  overlay,
  overlayShowing,
} from "./Project.module.css"

const Project = (props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [zoomedImage, setZoomedImage] = useState(false)

  function handleClick() {
    setShowOverlay(!showOverlay)
    setZoomedImage(!zoomedImage)
  }

  if (!props.reversed) {
    return (
      <>
        <div className={`${overlay} ${showOverlay ? overlayShowing : ""}`} />
        <div className={section}>
          <div className={projectContainer}>
            <img
              className={`${projectImage} ${
                zoomedImage ? projectImageZoomed : ""
              }`}
              src={props.image}
              alt={props.imageAlt}
              onClick={handleClick}
            />
            <ProjectDetails {...props} />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={`${overlay} ${showOverlay ? overlayShowing : ""}`} />
        <div className={[section, sectionShaded].join(" ")}>
          <div
            className={[projectContainer, projectContainerReverse].join(" ")}
          >
            <img
              className={`${projectImage} ${projectImageReverse} ${
                zoomedImage ? projectImageReversedZoomed : ""
              }`}
              src={props.image}
              alt={props.imageAlt}
              onClick={handleClick}
            />
            <ProjectDetails {...props} />
          </div>
        </div>
      </>
    )
  }
}

export default Project
