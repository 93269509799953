import styles from "./NavFooter.module.css"
import { linkedinPNG, githubPNG, twitterPNG, resumePNG } from "../static/assets"

const NavIcon = ({ staticLink, linkUrl, altTag }) => {
  return (
    <a href={linkUrl} target="_blank" rel="noreferrer">
      <img src={staticLink} height="48px" width="48px" alt={altTag} />
    </a>
  )
}

const NavFooter = () => {
  return (
    <footer>
      <div className={styles.navIcons}>
        <div className={styles.iconContainer}>
          <NavIcon
            staticLink={linkedinPNG}
            linkUrl="https://www.linkedin.com/in/colerutledge/"
            altTag="linkedin-ico"
          />
          <NavIcon
            staticLink={githubPNG}
            linkUrl="https://github.com/ColeRutledge"
            altTag="github-ico"
          />
          <NavIcon
            staticLink={twitterPNG}
            linkUrl="https://twitter.com/ColeRutledge"
            altTag="twitter-ico"
          />
          <NavIcon
            staticLink={resumePNG}
            linkUrl="https://docs.google.com/document/d/13y5MLByh7rpOLKND2x5jd00PPmzsigXqVovsXHHjs7Q/export?format=pdf"
            altTag="resume-ico"
          />
        </div>
      </div>
      <p>Created by Cole Rutledge (2021)</p>
    </footer>
  )
}

export default NavFooter
