import { Route, Switch } from "react-router-dom"
import About from "./components/About"
import Start from "./components/Start"

const App = () => {
  return (
    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/">
        <Start />
      </Route>
    </Switch>
  )
}

export default App
