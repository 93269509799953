import styles from "./AboutInfo.module.css"

const AboutInfo = () => {
  return (
    <div className={styles.aboutContainer}>
      <div className={`${styles.aboutContent} ${styles.section}`}>
        <h2 className={styles.fadeInH2}>Hello!</h2>
        <p className={styles.fadeInP}>
          My name is Cole. I used to help developers find jobs with startups in
          NYC. Now I'm a developer and love coding. I've recently become
          obsessed with build automation and infrastructure as code. Trained by
          App Academy in the ways of <span>Python</span> and
          <span> JavaScript</span>.
        </p>
        <p className={styles.fadeInP2}>
          Please check out some of my projects <span>below</span>!
        </p>
      </div>
    </div>
  )
}

export default AboutInfo
